import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Subtitle, ReportCard } from './../../components/library'

function section() {
    return (
        <LibraryWrapper>
            <Subtitle title="Spirometer Report" shareUrl="https://google.com" />
            <ReportCard 
                title="02/22/2020" 
                grades={[
                    {
                        label: 'FEV1',
                        value: '68%',
                        level: 1
                    },
                    {
                        label: 'FEV1/FVC',
                        value: '71%',
                        level: 1
                    },
                    {
                        label: 'FEV36',
                        value: '68%',
                        level: 3
                    },
                ]} 
            />
            <ReportCard 
                title="02/21/2020" 
                grades={[
                    {
                        label: 'FEV1',
                        value: '82%',
                        level: 5
                    },
                    {
                        label: 'FEV1/FVC',
                        value: '79%',
                        level: 2
                    },
                ]} 
            />
        </LibraryWrapper>
    )
}
export default section
